import * as axiosService from "../../axiosRequests";
import { endpoint } from "../../../utils/endpoint";

const USER_TRANSACTIONS_ENDPOINT = (
  campaignId: string,
  companyTransactionId: string,
) => endpoint(`app/v1.0/campaigns/${campaignId}/company_transactions/${companyTransactionId}/user_transactions`);

export async function getUserTransactions(
  campaignId: string,
  companyTransactionId: string,
  payload: { page?: number } = {},
) {
  const response = await axiosService.axiosGet(
    USER_TRANSACTIONS_ENDPOINT(campaignId, companyTransactionId),
    payload,
  );

  return response.data;
}

export default {
  getUserTransactions,
};
