import {
  action,
  makeAutoObservable,
  observable,
} from "mobx";
import { Meta } from "../types/Meta";
import { IUserTransactionTotals } from "../types/ICompanyTransaction";
import { IUserTransaction } from "../types/IUserTransaction";
import { uniqueOnId } from "../utils/arrays";
import { CompanyTransactionsStore } from "./CompanyTransactionsStore";
import * as userTransactionsService from "../requests/campaigns/companyTransactions/userTransactions";

type UserTransactionsStoreMeta = Meta & {
  transactionTotalsPerUser: IUserTransactionTotals;
};

export class UserTransactionsStore {
  companyTransactionsStore: CompanyTransactionsStore;

  @observable userTransactions: IUserTransaction[] = [];

  @observable transactionTotalsPerUser: IUserTransactionTotals = {};

  @observable isLoading = true;

  @observable meta: UserTransactionsStoreMeta = {
    count: 0,
    page: {
      current: 1,
      next: null,
      more: false,
    },
    transactionTotalsPerUser: {},
  };

  constructor(companyTransactionsStore: CompanyTransactionsStore) {
    makeAutoObservable(this);
    this.companyTransactionsStore = companyTransactionsStore;
  }

  @action
  setUserTransactions(userTransactions: IUserTransaction[]) {
    this.userTransactions = userTransactions;
  }

  @action
  setTransactionTotalsPerUser(transactionTotalsPerUser: IUserTransactionTotals) {
    this.transactionTotalsPerUser = transactionTotalsPerUser;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setMeta(meta: UserTransactionsStoreMeta) {
    this.meta = meta;
  }

  @action
  async getUserTransactionsAsync(companyTransactionId: string) {
    this.setIsLoading(true);
    try {
      const { data: userTransactions, meta } = await userTransactionsService.getUserTransactions(
        this.companyTransactionsStore.rootStore.campaignId,
        companyTransactionId,
      );
      this.setUserTransactions(userTransactions);
      this.setMeta(meta);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async getNextUserTransactionsAsync(companyTransactionId: string) {
    const { page } = this.meta;

    if (!page.more || page.next === null) {
      return;
    }

    const { data: userTransactions, meta } = await userTransactionsService.getUserTransactions(
      this.companyTransactionsStore.rootStore.campaignId,
      companyTransactionId,
      { page: page.next },
    );
    this.setUserTransactions(uniqueOnId([...this.userTransactions, ...userTransactions], "id"));
    this.setMeta(meta);
  }
}

export default UserTransactionsStore;
