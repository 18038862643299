import * as axiosService from "../axiosRequests";
import { ICompanyTransactionPayload } from "../../types/ICompanyTransaction";
import { endpoint } from "../../utils/endpoint";

const COMPANY_TRANSACTIONS_ENDPOINT = (campaignId: string) => endpoint(`app/v1.0/campaigns/${campaignId}/company_transactions`);

export const COMPANY_TRANSACTION_ENDPOINT = (
  campaignId: string,
  id: string,
  format: string,
) => endpoint(
  `app/v1.0/campaigns/${campaignId}/company_transactions/${id}.${format}`,
);

const NEW_COMPANY_TRANSACTION_ENDPOINT = (campaignId: string) => endpoint(`app/v1.0/campaigns/${campaignId}/company_transactions/new`);

export async function getCompanyTransactions(campaignId: string, payload: { page?: number } = {}) {
  const response = await axiosService.axiosGet(
    COMPANY_TRANSACTIONS_ENDPOINT(campaignId),
    payload,
  );
  return response.data;
}

export async function getCompanyTransaction(campaignId: string, id: string) {
  const response = await axiosService.axiosGet(
    COMPANY_TRANSACTION_ENDPOINT(campaignId, id, "json"),
  );

  return response.data;
}

export async function postCompanyTransaction(
  campaignId: string,
  payload: ICompanyTransactionPayload,
) {
  const response = await axiosService.axiosPost(
    COMPANY_TRANSACTIONS_ENDPOINT(campaignId),
    { company_transaction: payload },
  );
  return response.data;
}

export async function newCompanyTransaction(campaignId: string) {
  const response = await axiosService.axiosGet(
    NEW_COMPANY_TRANSACTION_ENDPOINT(campaignId),
  );
  return response.data;
}

export default {
  getCompanyTransactions,
  postCompanyTransaction,
  newCompanyTransaction,
};
