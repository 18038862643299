import moment from "moment";
import {
  action,
  computed,
  makeAutoObservable,
  observable,
} from "mobx";
import {
  ICompanyTransaction,
  ICompanyTransactionDetails,
  ICompanyTransactionDetailsAttributes,
  ICompanyTransactionPayload,
} from "../types/ICompanyTransaction";
import { Meta } from "../types/Meta";
import { uniqueOnId } from "../utils/arrays";
import { RootStore } from "./RootStore";
import { UserTransactionsStore } from "./UserTransactionsStore";
import * as companyTransactionsService from "../requests/campaigns/companyTransactions";

type Transaction = ICompanyTransaction | ICompanyTransactionDetails;

export class CompanyTransactionsStore {
  rootStore;

  userTransactionsStore;

  @observable transactions: Transaction[] = [];

  @observable generatedTransaction: ICompanyTransactionDetailsAttributes | null = null;

  @observable isLoading = true;

  @observable isSaving = false;

  @observable meta: Meta = {
    count: 0,
    page: {
      current: 1,
      next: null,
      more: false,
    },
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.userTransactionsStore = new UserTransactionsStore(this);
  }

  @computed get transactionsByYear() {
    const years = this.transactions.map((transaction) => (
      moment(transaction.attributes.updatedAt).year()
    ));

    const uniqueYears = Array.from(new Set(years)).sort((a, b) => b - a);

    const groupedTransactions = uniqueYears.map((year) => {
      const yearHash: Record<string, ICompanyTransaction[]> = {};

      const transactionsForYear = this.transactions.filter(
        (transaction) => moment(transaction.attributes.updatedAt).year() === year,
      );

      yearHash[year] = transactionsForYear;

      return yearHash;
    });

    return groupedTransactions;
  }

  @action
  setTransactions(transactions: ICompanyTransaction[]) {
    this.transactions = transactions;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setGeneratedTransaction(transaction: ICompanyTransactionDetailsAttributes) {
    this.generatedTransaction = transaction;
  }

  @action
  setMeta(meta: Meta) {
    this.meta = meta;
  }

  @action
  getTransaction(transactionId: string) {
    const transaction = this.transactions.find(
      (trans) => trans.id === transactionId,
    );

    return transaction;
  }

  @action
  async getCompanyTransactionsAsync() {
    this.setIsLoading(true);

    try {
      const { data: transactions, meta } = await companyTransactionsService.getCompanyTransactions(
        this.rootStore.campaignId,
      );

      this.setTransactions(transactions);
      this.setMeta(meta);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async getNextCompanyTransactionsAsync() {
    const { page } = this.meta;

    if (!page.more || page.next === null) {
      return;
    }

    const { data: transactions, meta } = await companyTransactionsService.getCompanyTransactions(
      this.rootStore.campaignId,
      { page: page.next },
    );

    this.setTransactions(uniqueOnId([...this.transactions, ...transactions], "id"));
    this.setMeta(meta);
  }

  @action
  replaceTransaction(newTransaction: ICompanyTransactionDetails) {
    const indexOfTransaction = this.transactions.findIndex(
      (transaction) => transaction.id === newTransaction.id,
    );

    if (indexOfTransaction !== -1) {
      this.transactions.splice(indexOfTransaction, 1, newTransaction);
    } else {
      this.setTransactions([newTransaction, ...this.transactions]);
    }
  }

  @action
  async getCompanyTransactionAsync(id: string) {
    this.setIsLoading(true);
    const transaction = await companyTransactionsService.getCompanyTransaction(
      this.rootStore.campaignId,
      id,
    );

    this.replaceTransaction(transaction);
    this.setIsLoading(false);
  }

  @action
  async generateCompanyTransaction() {
    this.setIsLoading(true);

    const transaction = await companyTransactionsService.newCompanyTransaction(
      this.rootStore.campaignId,
    );

    this.setGeneratedTransaction(transaction.attributes);

    this.setIsLoading(false);
    return transaction.attributes;
  }

  @action
  async createCompanyTransaction(payload: ICompanyTransactionPayload) {
    this.setIsLoading(true);
    const transaction = await companyTransactionsService.postCompanyTransaction(
      this.rootStore.campaignId,
      payload,
    );
    this.setTransactions([transaction, ...this.transactions]);
    this.setIsLoading(false);
    return transaction.attributes;
  }
}

export default CompanyTransactionsStore;
