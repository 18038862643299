import React from "react";
import { useTranslation } from "react-i18next";
import {
  Text, FontTypes, Avatar, AvatarSize, Flex,
} from "@wingmate/toolkit";
import ProfilePlaceholder from "../../../assets/images/grey-profile-placeholder.svg";
import { getNameOfUser } from "../../../utils/user";
import { UserAvatar } from "../../UserAvatar/UserAvatar";
import { IUserAttributes } from "../../../types/IUser";

import "./Username.scss";

export interface IUsernameProps {
  hideImage?: boolean;
  hideName?: boolean;
  size?: AvatarSize;
  showTooltip?: boolean;
  textType?: FontTypes;
  tooltipMessage?: string;
  user?: Pick<IUserAttributes, "disabled" | "fullName" | "id" | "profileImageUrl"> | null;
}

export const Username: React.FC<IUsernameProps> = ({
  hideImage = false,
  hideName = false,
  showTooltip = true,
  size,
  textType = "H6",
  tooltipMessage,
  user,
}) => {
  const { t } = useTranslation(["default", "common"]);

  const renderMissingProfileImage = () => <Avatar data-testid="photo--noUser" size={size} name={user?.fullName} src={ProfilePlaceholder} />;

  const renderProfileImage = () => {
    if (user) {
      return (
        <UserAvatar
          showTooltip={hideName && showTooltip}
          size={size}
          tooltipMessage={tooltipMessage}
          user={user}
        />
      );
    }
    return renderMissingProfileImage();
  };

  return (
    <Flex className="Username" data-testid="Username" align="center" gap={8}>
      {!hideImage && (
        <Flex className="Username__photo" align="center">{renderProfileImage()}</Flex>
      )}
      {!hideName && <Text className="Username__name" type={textType}>{getNameOfUser(t, user)}</Text>}
    </Flex>
  );
};

export default Username;
