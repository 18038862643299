import React, { ReactElement, ReactNode } from "react";

export interface IConditionalWrapperProps {
  children: ReactNode;
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
}

export const ConditionalWrapper: React.FC<IConditionalWrapperProps> = ({
  children,
  condition,
  wrapper,
}) => {
  if (condition) {
    return <>{wrapper(children)}</>;
  }

  return (
    children as ReactElement
  );
};
